import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";

import githubIcon from "../assets/icons/github.svg";
import gitlabIcon from "../assets/icons/gitlab.svg";
import emailIcon from "../assets/icons/email.svg";

import style from "../styles/index.module.css";

const Index = () => {
  return (
    <>
      <SEO />
      <Layout>
        <div className={style.avatar}>
          <img src="images/avatar.jpeg" alt="avatar" />
        </div>

        <h1>Jacky Cheung</h1>
        <h2>Full Stack Software Engineer</h2>
        <p>A developer reduces work with tools in hand</p>

        <div className={style.contact}>
          {/* <a href="https://github.com/cyljacky/" aria-label="Github">
            <FontAwesomeIcon icon={faGithub} size="2x" />
          </a> */}
          <div>
            <a href="https://gitlab.com/ylcheung/" aria-label="Gitlab">
              <img src={gitlabIcon} />
            </a>
          </div>
          <div>
            <a href="mailto:ylcheungab@gmail.com" aria-label="Email">
              <img src={emailIcon} />
            </a>
          </div>
        </div>
      </Layout>
    </>
  );
};

// export const postsQuery = graphql`
//   query($limit: Int!, $skip: Int!) {
//     allMarkdownRemark(
//       filter: { fileAbsolutePath: { regex: "//posts//" } }
//       sort: { fields: [frontmatter___date], order: DESC }
//       limit: $limit
//       skip: $skip
//     ) {
//       edges {
//         node {
//           id
//           excerpt
//           frontmatter {
//             title
//             date(formatString: "DD MMMM YYYY")
//             path
//             author
//             excerpt
//             tags
//             coverImage {
//               childImageSharp {
//                 fluid(maxWidth: 800) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;

export default Index;
